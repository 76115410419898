<template>
  <div>
    <p class="font-sansDemi text-base text-white">
      On a scale of 1 to 5, how harmful would you consider {{ item.originalChannelName }} to society?
    </p>
    <div class="flex justify-between mt-6">
      <p class="font-monoDemi text-sm base-font-gray-lighter-170">Not Harmful</p>
      <p class="font-monoDemi text-sm base-font-gray-lighter-170">Harmful</p>
    </div>
    <div class="flex justify-between mt-10">
      <p
        v-for="item in scores_list"
        :key="item"
        class="relative flex items-center justify-center font-monoDemi text-sm text-white w-8 h-8 rounded-full cursor-pointer"
        :class="[ item == score ? 'purple-light-bg-300' : '' ]"
        @click="score = item"
      >
        {{ item }}
        <span 
          v-if="item == 0 || item == 5"
          class="absolute w-px h-7 bg-white -top-8 left-3"
        ></span>
      </p>
    </div>
    <button 
      @click="raiting"
      class="bg-white rounded-2xl px-4 py-1.5 font-monoDemi text-sm text-black mt-16"
      :class="[{
        'disabled:opacity-50': score == null
      }]"
      :disabled="score == null"
    >
      Submit
    </button>
  </div>
</template>

<script>
  export default {
    props: [ 'item' ],
    data(){
      return{
        score: null,
        scores_list: [ 0, 1, 2, 3, 4, 5 ]
      }
    },
    methods: {
      raiting(){
        this.$emit('raiting', {
          "channelId": this.item.originalChannelId,
          "type": "rate",
          "number": this.score,
          "channelName": this.item.originalChannelName,
          "thumbUrl": this.item.originalThumbUrl
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>